#tableBodega .MuiTableCell-root {
  padding: 5px;
}
#tableBodega .MuiPaper-elevation4 {
  box-shadow: none;
}
@media print {
  #tableBodega .card-header,
  #tableBodega .AcctionsButton,
  .main-footer  {
    display: none;
  }
  .content-wrapper{
    margin: auto;
  }
}
