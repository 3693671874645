.No {
  font-weight: bold;
  background-color: #a4a4a4;
}
#statement .table-responsive {
  zoom: 69% !important;
}
.MX,
.MXN {
  color: #ca0000 !important;
  text-align: right !important;
}
.USD {
  color: darkblue !important;
  text-align: right !important;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 2900px !important;
  }
}
