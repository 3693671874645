.edditOds{
    padding: 0rem 1rem;
    border: 1px solid #dee2e6 ;
}
.edditOds .modal-body, .edditOds .card-body{
    padding: 0rem;
}
.form-step1{
    padding-top: .5rem;
    height: calc(100vh - 60px - 1rem);
    overflow-y: auto;
    overflow-x: hidden;
    margin: auto;
    zoom: 85%;
}
.form-step2{
    padding-top: .5rem;
    height: calc(100vh - 95px - .5rem);
    overflow-y: auto;
    margin: auto;
}
.form-step3
{
    padding-top: .5rem;
    height: calc(100vh - 107px - 1rem);
    overflow-y: scroll;
    overflow-x: hidden;
    margin: auto;
}