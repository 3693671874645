.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.MUIDataTableHeadCell-fixedHeader-157 {
  position: inherit !important;
}
.MUIDataTableSelectCell-fixedHeader-126 {
  position: inherit !important;
}
.tab {
  display: flex;
}
.ReactModal__Overlay{
z-index: 1200;
} 
.ReactModal__Overlay--after-open{
z-index: 1200;
}

/* MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded */