.ReactModal__Overlay--after-open {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.MUIDataTableHeadCell-fixedHeaderCommon-150 {
  position: inherit !important;
}
.MUIDataTableSelectCell-fixedHeader-126 {
  position: inherit !important;
}
.tab {
  display: flex;
}
.MuiTableHead-root {
  position: inherit !important;
}
.MUIDataTableHeadCell-fixedHeaderCommon-148 {
  position: inherit !important;
}
.MUIDataTableHead-main-134 {
  position: inherit !important;
}
.MuiTableCell-root {
  position: inherit !important;
}
