.card-header {
  font-size: 0.8rem !important;
}
.table-sm th,
.table-sm td {
  text-align: center;
  vertical-align: middle !important;
}
.align-top{
  vertical-align: top !important;
}
.data {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  margin: 0 !important;
}
.currency {
  text-align: right !important;
}
hr {
  margin-top: unset !important;
  margin-bottom: unset !important;
}
@media print{
  body{
    margin: 1cm 1cm 1cm 2cm !important;
    font-size: 12px !important;
  }
  .col-md-6{
    width: 50% !important;
  }
  .no-print{
    display: none;
  }
}