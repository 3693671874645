.Stock .info-box {
  height: auto !important;
  min-height: auto !important;
  padding: 0px !important;
  margin-bottom: .25rem !important;
}
.Stock .info-box-icon {
  width: 25px !important;
  font-size: 1rem !important;
}
.Stock .info-box .info-box-content{
  padding: 0px 5px 0px 0px;
}