.MuiFormControl-root {
  width: 100% !important;
}
.button-filter{
  margin-top: 1rem;
}
#calendar .fc-event,
#calendar .fc-list-item {
  cursor: pointer !important;
}
.nav-link,
#calendar .fc-day-header,
#calendar .fc-day,
.MuiTypography-body1 {
  text-transform: capitalize !important;
}
.fc-center,
.fc-toolbar-title {
  text-transform: uppercase !important;
}
#calendar .card-body .fc {
  margin: 0;
}
#calendar .fc-scroller {
  height: 100%;
  overflow: auto;
}
#calendar .fc-toolbar {
  margin-bottom: 0px;
}
@media (max-width: 575.98px) {
  .button-filter{
    margin-top: 1.5rem;
  }
  .fc .fc-toolbar-title {
    font-size: 1.2rem;
    margin-top: 1.2rem;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk {
    font-size: 12px;
    text-align: center !important;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk .btn-group,
  .fc-search_custom-button,
  .fc-add_custom-button,
  .fc-colection_custom-button {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 575.99px) and (max-width: 767.98px) {
  .button-filter{
    margin-top: 1.5rem;
  }
  .fc .fc-toolbar-title {
    font-size: 1rem;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk {
    font-size: 12px;
    text-align: center !important;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk .btn-group,
  .fc-search_custom-button,
  .fc-add_custom-button,
  .fc-colection_custom-button {
    margin-top: 0.25rem !important;
  }
 }
