.videoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  
    .qrVideo {
      object-fit: cover;
      border: 1px solid #ddd;
      width: 250px;
      height: 250px;
      border-radius: 50px;
    }
  }
  
  .scannedText {
    word-wrap: break-word;
  }